<template>
	<div class="layindex">
		<!-- <div class="telbox">
			<img class="icontel" src="@/assets/icon-tel.png" alt="">
			<span class="teltext">电话</span>
		</div> -->
		<v-telephone></v-telephone>
		<img class="logoname" src="@/assets/logoname.png" alt="">
		<img class="ball" src="@/assets/ball.png" alt="">
		<div class="btnbox" @click="getCode">立即进入</div>
	</div>
</template>

<script>
import { Dialog } from 'cube-ui'
import config from '@/utils/config.js'
import telephone from '@/components/telephone.vue'
export default {
	name: 'entry',
	created () {
		this.getSystemInfo()
		this.getIsCode()
	},
	data () {
		return {

		}
	},
	components: {
		'v-telephone': telephone,
	},
	methods: {
		getSystemInfo () {
			this.$http.getSystemInfo().then(res => {
				if (res.code == 200) {
					this.systemInfo = res.data;
					window.localStorage.setItem('systemTelephone', res.data.phone)
				}
			}).catch(err => {
				console.log(err);
			});
		},
		goindex () {
			// return false;
			if (this.$store.state.bindMobile == 0) {
				Dialog.$create({
					type: 'alert',
					title: '提示',
					content: "请绑定您的手机号",
					//icon: 'cubeic-alert',
					confirmBtn: {
						text: '去绑定',
						active: true,
						disabled: false,
						href: 'javascript:;'
					},
					onConfirm: () => {
						this.$router.push({
							path: '/bind-mobile'
						})
					},
				}).show()
			} else {
				if (this.$route.path == '/entry') {
					window.location.href = '/#/botnav/index'
				}
			}

		},
		GetUrlParam (name) {
			var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
			var r = window.location.search.substr(1).match(reg);
			if (r != null) return unescape(r[2]);
			return null;
		},
		getCode () {
			const code = this.GetUrlParam("code");
			const local = window.location.href;
			if (code == null || code === "") {
				window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + config.appid + "&redirect_uri=" + encodeURIComponent(local) + "&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect";
			} else {
				this.getToken(code);
			}
		},
		getIsCode () {
			const code = this.GetUrlParam("code");
			if (code == null || code === "") {
				return false
			} else {
				this.getToken(code);
			}
		},
		getToken (code) {
			this.$http.getLogin({ 'code': code }).then(res => {
				if (res.code == 200) {
					this.$store.commit('settoken', res.data.token)
					localStorage.setItem('token', res.data.token)
					localStorage.setItem('level', res.data.level)
					localStorage.setItem('isSupper', res.data.is_supper)
					this.$store.commit('setbindMobile', res.data.bindMobile)
					this.$store.commit('setuserInfo', res.data.userInfo)
					this.goindex()
				}
			}).catch(err => {
				console.log(err);
			});
		},
	},
}
</script>

<style scoped lang="less">
.layindex {
	width: 100%;
	height: 100%;
	position: relative;
	background: url(../assets/indexbg.png) no-repeat center;
	background-size: cover;
	overflow: hidden;
	z-index: 9;

	.telbox {
		position: absolute;
		box-sizing: border-box;
		width: 57px;
		padding: 4px 8px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		right: 10px;
		top: 10px;
		z-index: 10;
		background-color: #F9A709;
		border-radius: 20px;

		.icontel {
			width: 14px;
			height: 15px;
		}

		.teltext {
			font-size: 12px;
			color: #FFFFFF;
		}
	}

	.logoname {
		width: 158px;
		height: 35px;
		margin: 37px auto 0;
		display: block;
	}

	.ball {
		width: 223px;
		height: 216px;
		margin: 89px auto 0;
		display: block;
	}

	.btnbox {
		width: 184px;
		height: 48px;
		text-align: center;
		line-height: 48px;
		background: #F9A709;
		box-shadow: 0px 4px 9px 1px rgba(0, 0, 0, 0.3);
		opacity: 0.9;
		border-radius: 48px;
		font-weight: 400;
		color: #FFFFFF;
		font-size: 20px;
		margin: 92px auto 0;
		display: block;
		letter-spacing: 2px;
	}
}
</style>
